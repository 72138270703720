@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;600;800&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");

@import "vars";
@import "animations";

body {
    margin: 0;
    font-family: "Rubik", sans-serif;
    overflow-x: hidden;
}

$calendarTileWidthLarge: 13.3vw;
$calendarTileWidthMedium: 14.9vw;
$calendarTileWidthMedSmall: 18vw;
$calendarTileWidthSmall: 22vw;
$calendarTileWidthMin: 30vw;

.CalendarContainer {
    width: 90vw;
    margin-left: 10vw;
    margin-bottom: 5em;

    @media all and (min-width: 1450px) {
        width: 80vw;
        margin-left: 20vw;
    }

    .calendarItemExplain {
        float: left;
        height: ($calendarTileWidthLarge * 2)-0.01;
        width: $calendarTileWidthLarge * 2;
        background-color: $blue;
        padding: 3em;
        box-sizing: border-box;
        position: relative;
        z-index: 4;
        display: flex;
        justify-content: center;
        flex-direction: column;

        span,
        strike {
            color: #fff;
            font-size: 2.6em;
            font-weight: 600;
            display: block;
        }

        strike {
            font-style: italic;
            opacity: 0.6;
        }

        @media all and (max-width: 1450px) {
            padding: 2em;

            span,
            strike {
                font-size: 2.3em;
            }
        }

        @media all and (max-width: 1200px) {
            padding: 2em;

            span,
            strike {
                font-size: 2em;
            }
        }

        @media all and (max-width: 991px) {
            padding: 1em;

            span,
            strike {
                font-size: 1.6em;
            }
        }

        @media all and (max-width: 768px) {
            padding: 1em;

            span,
            strike {
                font-size: 1.2em;
            }
        }
    }

    .calendarItem {
        float: left;
        height: $calendarTileWidthLarge;
        width: $calendarTileWidthLarge;
        padding: 2em;
        position: relative;
        box-sizing: border-box;
        cursor: pointer;
        transition: 0.3s;
        position: relative;

        &:before {
            transition: 0.3s;
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: transparent;
            z-index: -1;
            top: 0;
            left: 0;
        }
        &:after {
            background: rgb(231, 231, 231);
            background: linear-gradient(303deg, rgba(231, 231, 231, 1) 0%, rgba(255, 255, 255, 1) 100%);
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: $orange;
            z-index: -1;
            top: 0.2em;
            left: -0.2em;
        }

        &:hover {
            transform: translateY(-0.3em) translateX(0.3em) !important;
            z-index: 3;
            &:before {
                background-color: $orange;
                transform: translateY(0.5em) translateX(-0.5em) !important;
            }

            &.lock {
                transform: translate(0) !important;

                &:before {
                    display: none;
                    transform: translate(0) !important;
                }
            }
        }

        @media all and (max-width: 768px) {
            padding: 1em;
        }
        .date {
            font-weight: 600;
            font-size: 2em;
            color: $grey;

            @media all and (max-width: 768px) {
                font-size:1.3em;
            }
        }

        .detailsButton {
            position: absolute;
            bottom: 1em;
            right: 1em;
            border: none;
            background: transparent;
            font-size: 1.2em;
            color: $grey;

            &.active {
                color: $blue;
            }

            @media all and (max-width: 768px) {
                font-size:.9em;
                bottom:.5em;
                right:.5em;
            }
        }

        .CalendarItemName {
            strong {
                text-transform: uppercase;
                color: rgba($blue, 0.8);
                width: 90%;
                display: block;
                font-size: 1.5em;
                margin-top: 0.4em;

                @media all and (max-width: 1550px) {
                    font-size: 1.3em;
                }

                @media all and (max-width: 1450px) {
                    font-size: 1.2em;
                }

                @media all and (max-width: 768px) {
                    font-size: 1em;
                }

                @media all and (max-width: 650px) {
                    font-size: 0.8em;
                }
            }
        }

        &.today {
            &:not(.lock) {
                .date,
                .CalendarItemName,
                .detailsButton {
                    color: $orange;
                    strong {
                        color: $orange;
                    }
                }
            }
        }
    }

    @media all and (max-width: 1450px) {
        .calendarItemExplain {
            height: ($calendarTileWidthMedium * 2)-0.01;
            width: $calendarTileWidthMedium * 2;
        }
        .calendarItem {
            height: $calendarTileWidthMedium;
            width: $calendarTileWidthMedium;
        }
    }

    @media all and (max-width: 1200px) {
        .calendarItemExplain {
            height: ($calendarTileWidthMedSmall * 2)-0.01;
            width: $calendarTileWidthMedSmall * 2;
        }
        .calendarItem {
            height: $calendarTileWidthMedSmall;
            width: $calendarTileWidthMedSmall;
        }
    }

    @media all and (max-width: 991px) {
        .calendarItemExplain {
            height: ($calendarTileWidthSmall * 2)-0.01;
            width: $calendarTileWidthSmall * 2;
        }
        .calendarItem {
            height: $calendarTileWidthSmall;
            width: $calendarTileWidthSmall;
        }
    }

    @media all and (max-width: 768px) {
        .calendarItemExplain {
            height: ($calendarTileWidthMin * 2)-0.01;
            width: $calendarTileWidthMin * 2;
        }
        .calendarItem {
            height: $calendarTileWidthMin;
            width: $calendarTileWidthMin;
        }
    }

    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

.DetailsBox {
    transform: translateY(-1em);
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(#fff, 0.95);
    z-index: 5;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;

    &:focus,&:focus-visible,&:focus-visible{
        border:none !important;
        outline: none !important;
    }

    .DetailsBoxContainer {
        width: 60%;
        position: relative;

        @media all and (min-width: 1920px) {
            width: 991px;
        }
        @media all and (max-width: 768px) {
            height: 95vh;
        }

        .DetailsBoxActions,
        .DetailsBoxContent {
            transition: 0.3s;
            opacity: 0;
            visibility: hidden;
            transform: translateY(0);
        }

        .DetailsBoxActions {
            transition: 0.3s;
            background-color: $orange;
            padding: 1em;
            width: 100%;
            height: 40vh;
            position: absolute;
            z-index: 0;
            min-height: 50%;
            position: absolute;
            z-index: 1;
            top: -1em;
            left: 4.5em;
            text-align: right;
            display: flex;
            flex-direction: column-reverse;

            button {
                width: 1.5em;
                height: 1.5em;
                align-self: flex-end;
                justify-self: flex-end;
                border: none;
                background: transparent;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                cursor: pointer;
                font-size: 1.5em;

                &.closeButton {
                    position: absolute;
                    top: 0.5em;
                }

                &.switchButton {
                    &.disabled {
                        opacity: 0.3;
                    }
                }
            }
        }
        .DetailsBoxContent {
            transition: 0.3s;
            background-color: #fff;
            padding: 2em;
            width: 100%;
            height: 80vh;
            position: relative;
            z-index: 2;
            overflow: auto;
            left: -2em;
            box-shadow: 10px 10px 10px -3px rgba(#000, 0.1);

            .date {
                font-size: 4em;
                color: $lightgrey;
                position: absolute;

                @media all and (max-width: 768px) {
                    position: relative;
                }
            }

            .personInfo {
                display: flex;
                flex-direction: row;
                align-items: flex-end;

                .infos {
                    flex: 0.6;
                    justify-self: flex-start;
                    display: flex;
                    flex-direction: column;
                    span {
                        font-size: 1.8em;
                        color: rgb(172, 172, 172);
                        font-weight: 600;
                    }
                    strong {
                        font-size: 3em;
                        color: $orange;

                        @media all and (max-width: 768px) {
                            font-size: 1.8em;
                        }
                    }

                    p {
                        color: $grey;
                    }
                }

                .image {
                    flex: 0.4;
                    justify-content: flex-end;
                    align-items: flex-end;
                    text-align: right;
                    position: relative;

                    img {
                        justify-self: flex-end;
                        flex: 0.4;
                        width: 13em;
                        height: 17em;
                        object-fit: cover;
                        position: relative;
                        z-index: 1;
                        border: solid 3px rgb(245, 245, 245);
                        background:#FFF;
                    }

                    &:after {
                        position: absolute;
                        content: "";
                        display: block;
                        width: 13em;
                        height: 17em;
                        background-color: $orange;
                        z-index: 0;
                        top: 0.8em;
                        right: -0.5em;
                    }

                    @media all and (max-width: 768px) {
                        img,
                        &:after {
                            width: 7em;
                            height: 10em;
                        }
                    }
                }

                @media all and (max-width: 768px) {
                    flex-direction:column-reverse;

                    .image{
                        position:relative;
                        top:-5em;
                        margin-bottom:-5em;

                        img,&:after{
                            height:8em;
                            width:6em;
                        }
                    
                    }
                }
            }

            .links {
                padding: 1em 0;
                a {
                    display: inline-block;
                    padding: 0.3em 1em;
                    color: #fff;
                    border-radius: 1em;
                    background-color: $orange;
                    text-decoration: none;

                    &:first-child {
                        margin-right: 2em;
                    }
                }

                @media all and (max-width: 768px) {
                    width:80%;
                    padding:1em;
                    box-sizing: border-box;

                    a{
                        display:block;
                        width:100%;
                        text-align: center;
                        margin:.3em 0;
                    }
                }
            }

            .infosContainer {
                display: flex;
                flex-direction: row;

                .col {
                    height: 100%;
                    flex: 1;
                    width: 50%;

                    &:first-child {
                        margin-right: 0.5%;
                    }

                    &:last-child {
                        margin-left: 0.5%;
                    }

                    span.title {
                        display: block;
                        color: $grey;
                        margin-bottom: 1em;
                        margin-top: 0.5em;
                    }

                    .code {
                        height: 100%;
                        .codeSample {
                            background: rgb(26, 26, 26);
                            padding: 1em;
                            border-radius: 0.5em;
                            max-height: 100%;
                            overflow: auto;

                            pre {
                                color: rgb(175, 175, 175);
                            }
                        }
                    }

                    .bio {
                        padding: 1em;
                        box-sizing: border-box;
                        background-color: rgb(233, 233, 233);
                        width: 90%;
                        border-radius: 0.3em;

                        p {
                            line-height: 1.5em;
                            color: rgb(56, 56, 56);
                        }

                        a {
                            color: $orange;
                        }


                        @media all and (max-width:768px){
                            width:100%;
                        }
                    }
                }

                @media all and (max-width: 768px) {
                    flex-direction:column;

                    .col{
                        width:100%;
                    }
                }
            }
        }

        @media all and (max-width: 768px) {
            width:70%;
            .DetailsBoxContent{
                left:-4em;
            }

            .DetailsBoxActions{
                left:2.5em;
            }
        }
    }

    .DetailsBoxContent * {
        transition: 0.3s;
    }

    &.hide {
        .DetailsBoxContent > div,
        .DetailsBoxContent > strong {
            opacity: 0;
            visibility: hidden;

            &.loading{
                opacity:1;
                visibility: visible;
            }
        }

        &.fromLeft {
            .DetailsBoxContent > div,
            .DetailsBoxContent > strong {
                transform: translateX(-0.3em);
            }
        }

        &.fromRight {
            .DetailsBoxContent > div,
            .DetailsBoxContent > strong {
                transform: translateX(0.3em);
            }
        }
    }

    &.open {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);

        .DetailsBoxContainer {
            .DetailsBoxActions {
                transition: 0.3s;
                transition-delay: 0.1s;
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
            .DetailsBoxContent {
                transition: 0.3s;
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
        }
    }
}


.header,.footer{
    .container{
        width: 75vw;
        margin-left:20vw;
        padding: 2em 0;
        box-sizing: border-box;

        @media all and (max-width:991px){
            width:80vw;
            margin-left:10vw;
        }
    }
}

.header{
    height:20vh;
    .container {
        max-width: 100vw;
        color: #fff;

        img{
            width:75px;
        }

        .row{
            display: flex;
            flex-direction: row;
            align-items: center;

            @media all and (max-width:768px){
                img{
                    margin-bottom:2em;
                }
            }
            .col{
        
                p{
                    display: block;
                    margin:0 1em;
                    font-size:.8em;
                    line-height: 1.3em;

                    a{
                        color:#FFF;
                    
                    }
                }


                &.right{
                    flex-grow:1;
                    text-align: right;
                    img{
                        width:20px;
                        height:20px;
                        margin-left:1em;
                        cursor: pointer;
                        transition:.2s;

                        &:hover{
                            transform:scale(1.06);
                        }
                    }
                }
            }
        }
    }
}

.footer {
    background: $blue;
    background: radial-gradient(circle, rgba(13, 19, 41, 1) 54%, rgba(2, 5, 15, 1) 100%);

    .container {
        max-width: 100vw;
        color: #fff;

        img{
            width:75px;
        }

        .row{
            display: flex;
            flex-direction: row;
            align-items: center;

            @media all and (max-width:768px){
                flex-direction: column;

                img{
                    margin-bottom:2em;
                }
            }
            .col{
        
                p{
                    display: block;
                    margin:0 1em;
                    font-size:.8em;
                    line-height: 1.3em;

                    a{
                        color:#FFF;
                    
                    }
                }
            }
        }
    }
}


/* width */
::-webkit-scrollbar {
    width: 5px;
    max-height: 5px;;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: rgb(204, 204, 204);
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $orange;
  }

  .loading{
    display:flex;
    width:100%;
    height:100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:grey;
  }


  .spinner {
    animation: spin infinite 2s linear;
    color:$orange;

    /*You can increase or decrease the timer (5s) to 
     increase or decrease the speed of the spinner*/
  }

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
