

.animation{
    transition: .3s;
   // opacity: 0;
    //visibility: hidden;

    transform:translateX(0) translateY(0) !important;

    &.up{
        transform:translateY(.5em);
    }

    &.down{
        transform:translateY(-.5em);
    }

    &.left{
        transform:translateX(.5em);
    }

    &.right{
        transform:translateX(-.5em);
    }

    &.animate{
        opacity:1;
        visibility: visible;
        transform:translateX(0) translateY(0);
    }


}