@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;600;800&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");
.animation {
  transition: 0.3s;
  transform: translateX(0) translateY(0) !important;
}
.animation.up {
  transform: translateY(0.5em);
}
.animation.down {
  transform: translateY(-0.5em);
}
.animation.left {
  transform: translateX(0.5em);
}
.animation.right {
  transform: translateX(-0.5em);
}
.animation.animate {
  opacity: 1;
  visibility: visible;
  transform: translateX(0) translateY(0);
}

body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  overflow-x: hidden;
}

.CalendarContainer {
  width: 90vw;
  margin-left: 10vw;
  margin-bottom: 5em;
}
@media all and (min-width: 1450px) {
  .CalendarContainer {
    width: 80vw;
    margin-left: 20vw;
  }
}
.CalendarContainer .calendarItemExplain {
  float: left;
  height: 26.59vw;
  width: 26.6vw;
  background-color: #0D1329;
  padding: 3em;
  box-sizing: border-box;
  position: relative;
  z-index: 4;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.CalendarContainer .calendarItemExplain span,
.CalendarContainer .calendarItemExplain strike {
  color: #fff;
  font-size: 2.6em;
  font-weight: 600;
  display: block;
}
.CalendarContainer .calendarItemExplain strike {
  font-style: italic;
  opacity: 0.6;
}
@media all and (max-width: 1450px) {
  .CalendarContainer .calendarItemExplain {
    padding: 2em;
  }
  .CalendarContainer .calendarItemExplain span,
.CalendarContainer .calendarItemExplain strike {
    font-size: 2.3em;
  }
}
@media all and (max-width: 1200px) {
  .CalendarContainer .calendarItemExplain {
    padding: 2em;
  }
  .CalendarContainer .calendarItemExplain span,
.CalendarContainer .calendarItemExplain strike {
    font-size: 2em;
  }
}
@media all and (max-width: 991px) {
  .CalendarContainer .calendarItemExplain {
    padding: 1em;
  }
  .CalendarContainer .calendarItemExplain span,
.CalendarContainer .calendarItemExplain strike {
    font-size: 1.6em;
  }
}
@media all and (max-width: 768px) {
  .CalendarContainer .calendarItemExplain {
    padding: 1em;
  }
  .CalendarContainer .calendarItemExplain span,
.CalendarContainer .calendarItemExplain strike {
    font-size: 1.2em;
  }
}
.CalendarContainer .calendarItem {
  float: left;
  height: 13.3vw;
  width: 13.3vw;
  padding: 2em;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
}
.CalendarContainer .calendarItem:before {
  transition: 0.3s;
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: -1;
  top: 0;
  left: 0;
}
.CalendarContainer .calendarItem:after {
  background: rgb(231, 231, 231);
  background: linear-gradient(303deg, rgb(231, 231, 231) 0%, rgb(255, 255, 255) 100%);
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #FF5C4E;
  z-index: -1;
  top: 0.2em;
  left: -0.2em;
}
.CalendarContainer .calendarItem:hover {
  transform: translateY(-0.3em) translateX(0.3em) !important;
  z-index: 3;
}
.CalendarContainer .calendarItem:hover:before {
  background-color: #FF5C4E;
  transform: translateY(0.5em) translateX(-0.5em) !important;
}
.CalendarContainer .calendarItem:hover.lock {
  transform: translate(0) !important;
}
.CalendarContainer .calendarItem:hover.lock:before {
  display: none;
  transform: translate(0) !important;
}
@media all and (max-width: 768px) {
  .CalendarContainer .calendarItem {
    padding: 1em;
  }
}
.CalendarContainer .calendarItem .date {
  font-weight: 600;
  font-size: 2em;
  color: rgb(140, 140, 140);
}
@media all and (max-width: 768px) {
  .CalendarContainer .calendarItem .date {
    font-size: 1.3em;
  }
}
.CalendarContainer .calendarItem .detailsButton {
  position: absolute;
  bottom: 1em;
  right: 1em;
  border: none;
  background: transparent;
  font-size: 1.2em;
  color: rgb(140, 140, 140);
}
.CalendarContainer .calendarItem .detailsButton.active {
  color: #0D1329;
}
@media all and (max-width: 768px) {
  .CalendarContainer .calendarItem .detailsButton {
    font-size: 0.9em;
    bottom: 0.5em;
    right: 0.5em;
  }
}
.CalendarContainer .calendarItem .CalendarItemName strong {
  text-transform: uppercase;
  color: rgba(13, 19, 41, 0.8);
  width: 90%;
  display: block;
  font-size: 1.5em;
  margin-top: 0.4em;
}
@media all and (max-width: 1550px) {
  .CalendarContainer .calendarItem .CalendarItemName strong {
    font-size: 1.3em;
  }
}
@media all and (max-width: 1450px) {
  .CalendarContainer .calendarItem .CalendarItemName strong {
    font-size: 1.2em;
  }
}
@media all and (max-width: 768px) {
  .CalendarContainer .calendarItem .CalendarItemName strong {
    font-size: 1em;
  }
}
@media all and (max-width: 650px) {
  .CalendarContainer .calendarItem .CalendarItemName strong {
    font-size: 0.8em;
  }
}
.CalendarContainer .calendarItem.today:not(.lock) .date,
.CalendarContainer .calendarItem.today:not(.lock) .CalendarItemName,
.CalendarContainer .calendarItem.today:not(.lock) .detailsButton {
  color: #FF5C4E;
}
.CalendarContainer .calendarItem.today:not(.lock) .date strong,
.CalendarContainer .calendarItem.today:not(.lock) .CalendarItemName strong,
.CalendarContainer .calendarItem.today:not(.lock) .detailsButton strong {
  color: #FF5C4E;
}
@media all and (max-width: 1450px) {
  .CalendarContainer .calendarItemExplain {
    height: 29.79vw;
    width: 29.8vw;
  }
  .CalendarContainer .calendarItem {
    height: 14.9vw;
    width: 14.9vw;
  }
}
@media all and (max-width: 1200px) {
  .CalendarContainer .calendarItemExplain {
    height: 35.99vw;
    width: 36vw;
  }
  .CalendarContainer .calendarItem {
    height: 18vw;
    width: 18vw;
  }
}
@media all and (max-width: 991px) {
  .CalendarContainer .calendarItemExplain {
    height: 43.99vw;
    width: 44vw;
  }
  .CalendarContainer .calendarItem {
    height: 22vw;
    width: 22vw;
  }
}
@media all and (max-width: 768px) {
  .CalendarContainer .calendarItemExplain {
    height: 59.99vw;
    width: 60vw;
  }
  .CalendarContainer .calendarItem {
    height: 30vw;
    width: 30vw;
  }
}
.CalendarContainer:after {
  content: "";
  display: block;
  clear: both;
}

.DetailsBox {
  transform: translateY(-1em);
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.95);
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}
.DetailsBox:focus, .DetailsBox:focus-visible, .DetailsBox:focus-visible {
  border: none !important;
  outline: none !important;
}
.DetailsBox .DetailsBoxContainer {
  width: 60%;
  position: relative;
}
@media all and (min-width: 1920px) {
  .DetailsBox .DetailsBoxContainer {
    width: 991px;
  }
}
@media all and (max-width: 768px) {
  .DetailsBox .DetailsBoxContainer {
    height: 95vh;
  }
}
.DetailsBox .DetailsBoxContainer .DetailsBoxActions,
.DetailsBox .DetailsBoxContainer .DetailsBoxContent {
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  transform: translateY(0);
}
.DetailsBox .DetailsBoxContainer .DetailsBoxActions {
  transition: 0.3s;
  background-color: #FF5C4E;
  padding: 1em;
  width: 100%;
  height: 40vh;
  position: absolute;
  z-index: 0;
  min-height: 50%;
  position: absolute;
  z-index: 1;
  top: -1em;
  left: 4.5em;
  text-align: right;
  display: flex;
  flex-direction: column-reverse;
}
.DetailsBox .DetailsBoxContainer .DetailsBoxActions button {
  width: 1.5em;
  height: 1.5em;
  align-self: flex-end;
  justify-self: flex-end;
  border: none;
  background: transparent;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  font-size: 1.5em;
}
.DetailsBox .DetailsBoxContainer .DetailsBoxActions button.closeButton {
  position: absolute;
  top: 0.5em;
}
.DetailsBox .DetailsBoxContainer .DetailsBoxActions button.switchButton.disabled {
  opacity: 0.3;
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent {
  transition: 0.3s;
  background-color: #fff;
  padding: 2em;
  width: 100%;
  height: 80vh;
  position: relative;
  z-index: 2;
  overflow: auto;
  left: -2em;
  box-shadow: 10px 10px 10px -3px rgba(0, 0, 0, 0.1);
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .date {
  font-size: 4em;
  color: rgb(234, 234, 234);
  position: absolute;
}
@media all and (max-width: 768px) {
  .DetailsBox .DetailsBoxContainer .DetailsBoxContent .date {
    position: relative;
  }
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .personInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .personInfo .infos {
  flex: 0.6;
  justify-self: flex-start;
  display: flex;
  flex-direction: column;
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .personInfo .infos span {
  font-size: 1.8em;
  color: rgb(172, 172, 172);
  font-weight: 600;
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .personInfo .infos strong {
  font-size: 3em;
  color: #FF5C4E;
}
@media all and (max-width: 768px) {
  .DetailsBox .DetailsBoxContainer .DetailsBoxContent .personInfo .infos strong {
    font-size: 1.8em;
  }
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .personInfo .infos p {
  color: rgb(140, 140, 140);
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .personInfo .image {
  flex: 0.4;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
  position: relative;
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .personInfo .image img {
  justify-self: flex-end;
  flex: 0.4;
  width: 13em;
  height: 17em;
  -o-object-fit: cover;
     object-fit: cover;
  position: relative;
  z-index: 1;
  border: solid 3px rgb(245, 245, 245);
  background: #FFF;
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .personInfo .image:after {
  position: absolute;
  content: "";
  display: block;
  width: 13em;
  height: 17em;
  background-color: #FF5C4E;
  z-index: 0;
  top: 0.8em;
  right: -0.5em;
}
@media all and (max-width: 768px) {
  .DetailsBox .DetailsBoxContainer .DetailsBoxContent .personInfo .image img, .DetailsBox .DetailsBoxContainer .DetailsBoxContent .personInfo .image:after {
    width: 7em;
    height: 10em;
  }
}
@media all and (max-width: 768px) {
  .DetailsBox .DetailsBoxContainer .DetailsBoxContent .personInfo {
    flex-direction: column-reverse;
  }
  .DetailsBox .DetailsBoxContainer .DetailsBoxContent .personInfo .image {
    position: relative;
    top: -5em;
    margin-bottom: -5em;
  }
  .DetailsBox .DetailsBoxContainer .DetailsBoxContent .personInfo .image img, .DetailsBox .DetailsBoxContainer .DetailsBoxContent .personInfo .image:after {
    height: 8em;
    width: 6em;
  }
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .links {
  padding: 1em 0;
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .links a {
  display: inline-block;
  padding: 0.3em 1em;
  color: #fff;
  border-radius: 1em;
  background-color: #FF5C4E;
  text-decoration: none;
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .links a:first-child {
  margin-right: 2em;
}
@media all and (max-width: 768px) {
  .DetailsBox .DetailsBoxContainer .DetailsBoxContent .links {
    width: 80%;
    padding: 1em;
    box-sizing: border-box;
  }
  .DetailsBox .DetailsBoxContainer .DetailsBoxContent .links a {
    display: block;
    width: 100%;
    text-align: center;
    margin: 0.3em 0;
  }
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .infosContainer {
  display: flex;
  flex-direction: row;
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .infosContainer .col {
  height: 100%;
  flex: 1;
  width: 50%;
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .infosContainer .col:first-child {
  margin-right: 0.5%;
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .infosContainer .col:last-child {
  margin-left: 0.5%;
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .infosContainer .col span.title {
  display: block;
  color: rgb(140, 140, 140);
  margin-bottom: 1em;
  margin-top: 0.5em;
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .infosContainer .col .code {
  height: 100%;
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .infosContainer .col .code .codeSample {
  background: rgb(26, 26, 26);
  padding: 1em;
  border-radius: 0.5em;
  max-height: 100%;
  overflow: auto;
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .infosContainer .col .code .codeSample pre {
  color: rgb(175, 175, 175);
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .infosContainer .col .bio {
  padding: 1em;
  box-sizing: border-box;
  background-color: rgb(233, 233, 233);
  width: 90%;
  border-radius: 0.3em;
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .infosContainer .col .bio p {
  line-height: 1.5em;
  color: rgb(56, 56, 56);
}
.DetailsBox .DetailsBoxContainer .DetailsBoxContent .infosContainer .col .bio a {
  color: #FF5C4E;
}
@media all and (max-width: 768px) {
  .DetailsBox .DetailsBoxContainer .DetailsBoxContent .infosContainer .col .bio {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  .DetailsBox .DetailsBoxContainer .DetailsBoxContent .infosContainer {
    flex-direction: column;
  }
  .DetailsBox .DetailsBoxContainer .DetailsBoxContent .infosContainer .col {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  .DetailsBox .DetailsBoxContainer {
    width: 70%;
  }
  .DetailsBox .DetailsBoxContainer .DetailsBoxContent {
    left: -4em;
  }
  .DetailsBox .DetailsBoxContainer .DetailsBoxActions {
    left: 2.5em;
  }
}
.DetailsBox .DetailsBoxContent * {
  transition: 0.3s;
}
.DetailsBox.hide .DetailsBoxContent > div,
.DetailsBox.hide .DetailsBoxContent > strong {
  opacity: 0;
  visibility: hidden;
}
.DetailsBox.hide .DetailsBoxContent > div.loading,
.DetailsBox.hide .DetailsBoxContent > strong.loading {
  opacity: 1;
  visibility: visible;
}
.DetailsBox.hide.fromLeft .DetailsBoxContent > div,
.DetailsBox.hide.fromLeft .DetailsBoxContent > strong {
  transform: translateX(-0.3em);
}
.DetailsBox.hide.fromRight .DetailsBoxContent > div,
.DetailsBox.hide.fromRight .DetailsBoxContent > strong {
  transform: translateX(0.3em);
}
.DetailsBox.open {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.DetailsBox.open .DetailsBoxContainer .DetailsBoxActions {
  transition: 0.3s;
  transition-delay: 0.1s;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.DetailsBox.open .DetailsBoxContainer .DetailsBoxContent {
  transition: 0.3s;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.header .container, .footer .container {
  width: 75vw;
  margin-left: 20vw;
  padding: 2em 0;
  box-sizing: border-box;
}
@media all and (max-width: 991px) {
  .header .container, .footer .container {
    width: 80vw;
    margin-left: 10vw;
  }
}

.header {
  height: 20vh;
}
.header .container {
  max-width: 100vw;
  color: #fff;
}
.header .container img {
  width: 75px;
}
.header .container .row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media all and (max-width: 768px) {
  .header .container .row img {
    margin-bottom: 2em;
  }
}
.header .container .row .col p {
  display: block;
  margin: 0 1em;
  font-size: 0.8em;
  line-height: 1.3em;
}
.header .container .row .col p a {
  color: #FFF;
}
.header .container .row .col.right {
  flex-grow: 1;
  text-align: right;
}
.header .container .row .col.right img {
  width: 20px;
  height: 20px;
  margin-left: 1em;
  cursor: pointer;
  transition: 0.2s;
}
.header .container .row .col.right img:hover {
  transform: scale(1.06);
}

.footer {
  background: #0D1329;
  background: radial-gradient(circle, rgb(13, 19, 41) 54%, rgb(2, 5, 15) 100%);
}
.footer .container {
  max-width: 100vw;
  color: #fff;
}
.footer .container img {
  width: 75px;
}
.footer .container .row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media all and (max-width: 768px) {
  .footer .container .row {
    flex-direction: column;
  }
  .footer .container .row img {
    margin-bottom: 2em;
  }
}
.footer .container .row .col p {
  display: block;
  margin: 0 1em;
  font-size: 0.8em;
  line-height: 1.3em;
}
.footer .container .row .col p a {
  color: #FFF;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  max-height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(204, 204, 204);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #FF5C4E;
}

.loading {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: grey;
}

.spinner {
  -webkit-animation: spin infinite 2s linear;
          animation: spin infinite 2s linear;
  color: #FF5C4E;
  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@-webkit-keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}/*# sourceMappingURL=main.css.map */